<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-11
 * @Description: 营销管理/平台营销工具/平台价格活动
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search ref="YkcSearch" :data="searchItems" @click="handleSearchConfirm" />
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              type="plain"
              v-rbac="'marketing:platform:price:addActivity'"
              @click="newActivity">
              新建活动
            </ykc-button>
            <ykc-button type="plain" v-rbac="'marketing:platform:price:export'" @click="exportBlob">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :page-size.sync="pageSize"
          :current-page.sync="currentPage"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange" />
      </ykc-table>
      <!-- drawer start -->
      <ykc-drawer
        :show.sync="showDrawer"
        :title="drawerTitle()"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            v-if="drawerType === 'edit'"
            :active="stepsIndex"
            ref="addOrEdit"
            :editModel="editModel"
            :id="currentId"></AddOrEdit>
        </div>
      </ykc-drawer>
      <!-- drawer end -->
    </div>
  </scroll-layout>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AddOrEdit from './AddOrEdit.vue';
  import { code, offlineExport } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { platformPriceActivity } from '@/service/apis';

  export default {
    name: 'platformPriceActivity',
    components: {
      AddOrEdit,
    },
    computed: {
      ...mapGetters(['getDic']),
    },
    data() {
      return {
        currentId: '',
        stepsIndex: 0,
        maxStepIndex: 2,
        showDrawer: false, // 是否展示抽屉
        editModel: 'add', // 模式：add copy edit
        drawerType: 'edit',
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            disabled: () => false,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.getData();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        configSet: {
          label: 'vehicleOrgName',
          value: 'vehicleOrgId',
        },
        searchParams: {},
        searchItems: [
          {
            comName: 'YkcInput',
            key: 'activityName',
            label: '活动名称',
            placeholder: '请输入活动名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '活动状态',
            placeholder: '请选择活动状态',
            data: this.dicStatus(), // 活动状态（1进行中 2未开始 3已停止 4已到期）,
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'startTime',
            label: '开始时间',
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'endTime',
            label: '结束时间',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '客户名称',
            placeholder: '请输入客户名称',
          },
          {
            comName: 'YkcInput',
            key: 'userGroupName',
            label: '用户分组',
            placeholder: '请输入用户分组',
          },
          {
            comName: 'YkcInput',
            key: 'stationGroupName',
            label: '电站分组',
            placeholder: '请输入电站分组',
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '活动编码', prop: 'activityId', minWidth: '150px' },
          { label: '活动名称', prop: 'activityName', minWidth: '150px' },
          { label: '开始时间', prop: 'startTime' },
          { label: '结束时间', prop: 'endTime' },
          {
            label: '活动状态',
            prop: 'status',
            scopedSlots: {
              default: ({ row }) => {
                const idcStatus = this.dicStatus();
                if (!row.status) {
                  return '——';
                }
                const status = idcStatus.filter(item => item.id === row.status)[0] || {};
                return status.name || '——';
              },
            },
          },
          { label: '修改人', prop: 'modifiedAccount' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '100px' },
        ],
        tableTitle: '活动列表',
        tableOperateButtons: [
          {
            enabled: () => code('marketing:platform:price:edit'),
            render: (h, button, { row }) => {
              if (row && row.status === '2') {
                return (
                  <ykc-button
                    type="text"
                    class="do-enable-btn"
                    onClick={() => {
                      this.editModel = 'edit';
                      this.currentId = row.activityId;
                      this.showDrawer = true;
                    }}>
                    编辑
                  </ykc-button>
                );
              }
              return (
                <ykc-button type="text" class="do-disable-btn" disabled>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('marketing:platform:price:stop'),
            render: (h, button, { row }) => {
              if (row && (row.status === '1' || row.status === '2')) {
                return (
                  <ykc-button
                    type="text"
                    class="do-enable-btn"
                    onClick={() => {
                      this.stopActivity(row);
                    }}>
                    停用
                  </ykc-button>
                );
              }
              return (
                <ykc-button type="text" class="do-disable-btn" disabled>
                  停用
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('marketing:platform:price:duplicate'),
            id: '1',
            text: '复制',
            handleClick: (btn, { row }) => {
              this.copyActivity(row);
            },
          },
          {
            enabled: () => code('marketing:platform:price:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.go2Detail(row);
            },
          },
        ],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
    },
    /// 生命周期
    created() {
      if (this.$route.query.stationName) {
        this.searchParams.stationName = this.$route.query.stationName;
      }
      this.getData();
    },
    methods: {
      /**
       * 点击查询
       * @param
       */
      newActivity() {
        this.showDrawer = true;
        this.currentId = '';
        this.editModel = 'add';
      },
      /**
       * 点击查询
       * @param form
       */
      handleSearchConfirm(form) {
        this.currentPage = 1;
        // 自营车队类型
        const search = JSON.parse(JSON.stringify(form));
        if (search.startTime && search.startTime.length === 2) {
          [search.startTimeBegin, search.startTimeEnd] = search.startTime;
        }
        if (search.endTime && search.endTime.length === 2) {
          [search.endTimeBegin, search.endTimeEnd] = search.endTime;
        }
        delete search.startTime;
        delete search.endTime;
        this.searchParams = search || {};
        this.getData();
      },
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
        console.log('列表按钮点击事件', button, event);
      },
      /**
       * 导出
       */
      exportBlob() {
        offlineExport({
          downloadType: 'marketing_export',
          jsonNode: {
            downloadKey: 'platform_activity_list',
            ...this.searchParams,
          },
        });
      },

      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageSize = size;
        this.currentPage = 1;
        this.getData();
      },
      /**
       * 抽屉标题
       * @returns {string}
       */
      drawerTitle() {
        return `${this.editModel !== 'edit' ? '新增' : '编辑'}活动`;
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            this.editModel = 'add';
          },
        });
      },
      getDictionary() {
        return [
          { id: 1, name: '进行中' },
          { id: 2, name: '未开始' },
          { id: 3, name: '已停止' },
          { id: 4, name: '已到期' },
        ]; // 活动状态（1进行中 2未开始 3已停止 4已到期）
      },
      /**
       * 取消之前
       * @param drawerDone
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
          },
        });
      },
      /**
       * @desc 获取远程数据
       * */
      getData() {
        platformPriceActivity
          .list({
            ...(this.searchParams || {}),
            current: this.currentPage,
            size: this.pageSize,
          })
          .then(res => {
            console.log(res);
            this.tableData = res?.records || [];
            this.total = res?.total;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 停止活动
       * @param item
       */
      stopActivity(item) {
        this.$dialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认停用当前活动吗?',
          onConfirm: done => {
            platformPriceActivity.stop({ activityId: item.activityId }).then(res => {
              console.log(res);
              done();
              this.$message({
                message: '停用成功',
                type: 'success',
              });
              this.currentPage = 1;
              this.getData();
            });
          },
        });
      },
      /**
       * 复制活动
       * @param item
       */
      copyActivity(item) {
        this.$dialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认复制当前活动吗?',
          onConfirm: done => {
            this.editModel = 'copy';
            this.currentId = item.activityId;
            this.showDrawer = true;
            done();
          },
        });
      },
      /**
       * 查看记录
       * @param item
       */
      exploreRecord(item) {
        const params = JSON.stringify({ id: item.activityId });
        this.$router.push({
          path: '',
          query: { data: params },
        });
      },
      /**
       * 打开详情
       * @param item
       */
      go2Detail(item) {
        this.$router.push({
          path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/details',
          query: { activityId: item.activityId, activityType: item.activityType },
        });
      },
      dicStatus() {
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已停止' },
          { id: '4', name: '已到期' },
        ];
      },
    },
  };
</script>
<style lang="scss" scoped></style>
